<template>
  <div class="route2">
    <!-- <img :src="iconPath" alt=""> -->
    <div class="content2">
      <component :is="textSection"></component>
      <!-- <img :src="imgPath" alt=""> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ['textSection', 'imgPath', 'iconPath'],
}
</script>
<style scoped>
.route2 {
  margin-top: 100px;
  padding-left: 280px;
  padding-right: 220px;
  /* background-color: aqua; */
}

.route2 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  /* margin-left: 74px; */
  /* 52.5px */
}

.content2 {
  /* margin-left: 63px; */
  /* display: flex; */
  /* background-color: red; */
  /* max-width: 2000px; */
}

@media screen and (max-width: 560px) {
  .route2 {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
  }

  .content2 {
    /* padding: 30px; */
  }

}

@media screen and (max-width: 1400px) {
  .content2 {
    max-width: 480px;
    /* padding: 0; */
  }

  .route2 {
    padding-left: 0;
    padding-right: 0;
    /* padding: 100px; */
  }

}
</style>
